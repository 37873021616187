import { LitElement, html, css } from 'lit';
import { property } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

class FacebookSignInRedirect extends LitElement {
  @property({ type: String, reflect: true }) redirectUrl = '';
  @property({ type: Boolean, reflect: true }) debugMode = false;
  @property({ type: Boolean }) isLoading = false;

  static styles = css`
    .fb-button {
      width: 100%;
      background-color: #3975ea;
      background-image: url('/images/facebook-logo.svg');
      background-position: 12px center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      border-radius: 5px;
      border: none;
      color: white;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      outline: none;
      text-align: center;
      text-decoration: none;
      text-indent: 30px;
      transition: background-color 0.3s;
      height: 40px;
      transition: background-color 0.3s, box-shadow 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
      white-space: nowrap;
    }
    .fb-button:hover {
      background-color: #365899;
    }
    .fb-button:focus {
      box-shadow: 0 0 0 3px rgba(55, 117, 234, 0.5);
    }
    .fb-button:disabled,
    .fb-button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .spinner {
      width: 100%;
      height: 100%;
      background-image: url('/images/loading.svg');
      background-position: center;
      background-repeat: no-repeat;
      margin: 0 auto;
    }
  `;

  render() {
    return html`
      <button class="fb-button" @click="${this.redirectToFacebookSignIn}">
        ${this.isLoading ? html`<div class="spinner"></div>` : 'Continue with Facebook'}
      </button>
    `;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  async redirectToFacebookSignIn() {
    this.isLoading = true;
    this.requestUpdate();
    try {
      await Ayetu.auth.signIn('Facebook');
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.requestUpdate();
    }
  }
}

customElements.define('facebook-signin', FacebookSignInRedirect);
